// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InfoCompany_btn__5BGJL {\r\n\tbackground-color: #fcc31f;\r\n\tborder: 1px solid #fcc31f !important;\r\n\tcolor: #fff !important;\r\n}\r\n.InfoCompany_btn__5BGJL:hover {\r\n\tbackground-color: transparent !important;\r\n\tcolor: #fcc31f !important;\r\n}\r\n\r\n.InfoCompany_detail-title__lu0qs {\r\n\tmin-width: 100px;\r\n\tfont-size: 16px;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/Layouts/InfoCompany/InfoCompany.module.css"],"names":[],"mappings":"AAAA;CACC,yBAAyB;CACzB,oCAAoC;CACpC,sBAAsB;AACvB;AACA;CACC,wCAAwC;CACxC,yBAAyB;AAC1B;;AAEA;CACC,gBAAgB;CAChB,eAAe;AAChB","sourcesContent":[".btn {\r\n\tbackground-color: #fcc31f;\r\n\tborder: 1px solid #fcc31f !important;\r\n\tcolor: #fff !important;\r\n}\r\n.btn:hover {\r\n\tbackground-color: transparent !important;\r\n\tcolor: #fcc31f !important;\r\n}\r\n\r\n.detail-title {\r\n\tmin-width: 100px;\r\n\tfont-size: 16px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btn": "InfoCompany_btn__5BGJL",
	"detail-title": "InfoCompany_detail-title__lu0qs"
};
export default ___CSS_LOADER_EXPORT___;
