// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".User_avatar__i34Tp {\r\n    width: 35px;\r\n    height: 35px;\r\n    border-radius: 50%;\r\n}", "",{"version":3,"sources":["webpack://./src/Layouts/User/User.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB","sourcesContent":[".avatar {\r\n    width: 35px;\r\n    height: 35px;\r\n    border-radius: 50%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatar": "User_avatar__i34Tp"
};
export default ___CSS_LOADER_EXPORT___;
