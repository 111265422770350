const periodDate = [
    {
        id: 1,
        name: 'Today',
        value: 'today',
    },
    {
        id: 2,
        name: 'Yesterday',
        value: 'yesterday',
    },
    {
        id: 3,
        name: 'This week',
        value: 'this_week',
    },
    {
        id: 4,
        name: 'Last week',
        value: 'last_week',
    },
    {
        id: 5,
        name: 'This month',
        value: 'this_month',
    },
    {
        id: 6,
        name: 'Last month',
        value: 'last_month',
    },
    {
        id: 7,
        name: 'This year',
        value: 'this_year',
    },
    {
        id: 8,
        name: 'Last year',
        value: 'last_year',
    },
    // {
    //     id: 9,
    //     name: 'All time',
    //     value: 'all_time',
    // },
];

export default periodDate;
