// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RecuiterContent_header_title__BIpCY {\r\n\tfont-weight: bold;\r\n\tfont-size: 20px;\r\n\tline-height: 1.6;\r\n\ttext-decoration: underline;\r\n\tfont-style: italic;\r\n\tpadding: 0;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/Layouts/RecuiterContent/RecuiterContent.module.css"],"names":[],"mappings":"AAAA;CACC,iBAAiB;CACjB,eAAe;CACf,gBAAgB;CAChB,0BAA0B;CAC1B,kBAAkB;CAClB,UAAU;AACX","sourcesContent":[".header_title {\r\n\tfont-weight: bold;\r\n\tfont-size: 20px;\r\n\tline-height: 1.6;\r\n\ttext-decoration: underline;\r\n\tfont-style: italic;\r\n\tpadding: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header_title": "RecuiterContent_header_title__BIpCY"
};
export default ___CSS_LOADER_EXPORT___;
