// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Withdraw_delete-button__HLVjM {\r\n    background-color: var(--color-btn-delete);\r\n    color: var(--color-white);\r\n}\r\n\r\n.Withdraw_delete-button__HLVjM:hover {\r\n    background-color: var(--color-btn-delete-hover);\r\n    color: var(--color-white);\r\n}", "",{"version":3,"sources":["webpack://./src/Layouts/Withdraw/Withdraw.module.css"],"names":[],"mappings":"AAAA;IACI,yCAAyC;IACzC,yBAAyB;AAC7B;;AAEA;IACI,+CAA+C;IAC/C,yBAAyB;AAC7B","sourcesContent":[".delete-button {\r\n    background-color: var(--color-btn-delete);\r\n    color: var(--color-white);\r\n}\r\n\r\n.delete-button:hover {\r\n    background-color: var(--color-btn-delete-hover);\r\n    color: var(--color-white);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"delete-button": "Withdraw_delete-button__HLVjM"
};
export default ___CSS_LOADER_EXPORT___;
