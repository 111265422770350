// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Login_login-forgotpwd-link__8EJ8f {\r\n\tcolor: var(--color-primary);\r\n\tfont-weight: 700;\r\n\tfont-size: 14px;\r\n\tline-height: 18px;\r\n\ttext-align: right;\r\n\twidth: 100%;\r\n\tmargin: 14px 0;\r\n}\r\n\r\n.Login_login-forgotpwd-link__8EJ8f:hover {\r\n\tcolor: var(--color-primary);\r\n\ttext-decoration: none;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/Layouts/Login/Login.module.css"],"names":[],"mappings":"AAAA;CACC,2BAA2B;CAC3B,gBAAgB;CAChB,eAAe;CACf,iBAAiB;CACjB,iBAAiB;CACjB,WAAW;CACX,cAAc;AACf;;AAEA;CACC,2BAA2B;CAC3B,qBAAqB;AACtB","sourcesContent":[".login-forgotpwd-link {\r\n\tcolor: var(--color-primary);\r\n\tfont-weight: 700;\r\n\tfont-size: 14px;\r\n\tline-height: 18px;\r\n\ttext-align: right;\r\n\twidth: 100%;\r\n\tmargin: 14px 0;\r\n}\r\n\r\n.login-forgotpwd-link:hover {\r\n\tcolor: var(--color-primary);\r\n\ttext-decoration: none;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"login-forgotpwd-link": "Login_login-forgotpwd-link__8EJ8f"
};
export default ___CSS_LOADER_EXPORT___;
