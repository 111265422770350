// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Toggle_toggle-container__P2V\\+h {\r\n    display: flex;\r\n    align-items: center;\r\n}\r\n\r\n.Toggle_toggle-container__P2V\\+h p {\r\n    margin-left: 10px;\r\n}\r\n\r\n.Toggle_toggle-label__HaS7D {\r\n    font-size: 14px;\r\n    font-style: normal;\r\n    font-weight: 400;\r\n    height: 22px;\r\n    line-height: 22px;\r\n    width: 256px;\r\n}", "",{"version":3,"sources":["webpack://./src/components/Toggle/Toggle.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,YAAY;IACZ,iBAAiB;IACjB,YAAY;AAChB","sourcesContent":[".toggle-container {\r\n    display: flex;\r\n    align-items: center;\r\n}\r\n\r\n.toggle-container p {\r\n    margin-left: 10px;\r\n}\r\n\r\n.toggle-label {\r\n    font-size: 14px;\r\n    font-style: normal;\r\n    font-weight: 400;\r\n    height: 22px;\r\n    line-height: 22px;\r\n    width: 256px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"toggle-container": "Toggle_toggle-container__P2V+h",
	"toggle-label": "Toggle_toggle-label__HaS7D"
};
export default ___CSS_LOADER_EXPORT___;
