// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PageNotFound_page-not-found-container__68Vas {\r\n    width: 100%;\r\n    min-height: 100vh;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n}\r\n\r\n.PageNotFound_page-not-found-image__Fmx2L {\r\n    width: 300px;\r\n    height: 300px;\r\n}\r\n\r\n.PageNotFound_backToHome__fqHWt {\r\n    font-size: 20px;\r\n    margin: 12px 0;\r\n}", "",{"version":3,"sources":["webpack://./src/components/PageNotFound/PageNotFound.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,eAAe;IACf,cAAc;AAClB","sourcesContent":[".page-not-found-container {\r\n    width: 100%;\r\n    min-height: 100vh;\r\n    display: flex;\r\n    flex-direction: column;\r\n    align-items: center;\r\n    justify-content: center;\r\n}\r\n\r\n.page-not-found-image {\r\n    width: 300px;\r\n    height: 300px;\r\n}\r\n\r\n.backToHome {\r\n    font-size: 20px;\r\n    margin: 12px 0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"page-not-found-container": "PageNotFound_page-not-found-container__68Vas",
	"page-not-found-image": "PageNotFound_page-not-found-image__Fmx2L",
	"backToHome": "PageNotFound_backToHome__fqHWt"
};
export default ___CSS_LOADER_EXPORT___;
