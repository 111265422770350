// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MultipleUpload_container__0ema5 {\r\n\tpadding: 0;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/components/MultipleUpload/MultipleUpload.module.css"],"names":[],"mappings":"AAAA;CACC,UAAU;AACX","sourcesContent":[".container {\r\n\tpadding: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "MultipleUpload_container__0ema5"
};
export default ___CSS_LOADER_EXPORT___;
