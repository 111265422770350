const routers = {
	// ADMIN ROUTER
	home: '/',
	login: '/login',
	register: '/register',
	forgotPwd: '/forgot-password',
	resetPwd: '/reset-password',
	dashboard: '/dashboard',
	deposits: '/deposits',
	depositsDetail: 'deposit-detail',
	withdraw: '/withdraw',
	withdrawDetail: 'withdraw-detail',
	contractUsd: '/contract-usd',
	contractAgriculture: '/contract-agriculture',
	user: '/user',
	content: '/content',
	infoCompany: '/info-company',
	createhomecontent: 'create-home-content',
	updatehomecontent: 'update-content-home',
	createintroducecontent: 'create-introduce-content',
	updateintroducecontent: 'update-content-introduce',
	createfinancecontent: 'create-finance-content',
	updatefinancecontent: 'update-content-finance',
	createtrainingcontent: 'create-training-content',
	updatetrainingcontent: 'update-content-training',
	createwebcontent: 'create-web-content',
	updatewebcontent: 'update-content-web',
	createsoftwarecontent: 'create-software-content',
	updatesoftwarecontent: 'update-content-software',
	createrecuitercontent: 'create-recuiter-content',
	updaterecuitercontent: 'update-content-recuiter',
	createcontactcontent: 'create-contact-content',
	updatecontactcontent: 'update-content-contact',
	createforumcontent: 'create-forum-content',
	updateforumcontent: 'update-forum-contact',
	createdevelopercontent: 'create-developer-content',
	updatedevelopercontent: 'update-developer-contact',
	pageNotFound: '*',
};

export default routers;
