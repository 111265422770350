// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Breadcrumb_breadcrumb-link__2QW53 {\r\n    text-decoration: none;\r\n    display: flex;\r\n    align-items: center;\r\n    color: var(--breadcrumb-link-color);\r\n    font-size: 14px;\r\n    font-weight: 600;\r\n    line-height: 20px;\r\n    font-family: 'Times New Roman', Times, serif;\r\n    text-transform: capitalize;\r\n}\r\n\r\n.Breadcrumb_breadcrumb-link__2QW53:hover {\r\n    text-decoration: none;\r\n    color: var(--breadcrumb-link-color);\r\n}", "",{"version":3,"sources":["webpack://./src/components/Breadcrumb/Breadcrumb.module.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;IACrB,aAAa;IACb,mBAAmB;IACnB,mCAAmC;IACnC,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,4CAA4C;IAC5C,0BAA0B;AAC9B;;AAEA;IACI,qBAAqB;IACrB,mCAAmC;AACvC","sourcesContent":[".breadcrumb-link {\r\n    text-decoration: none;\r\n    display: flex;\r\n    align-items: center;\r\n    color: var(--breadcrumb-link-color);\r\n    font-size: 14px;\r\n    font-weight: 600;\r\n    line-height: 20px;\r\n    font-family: 'Times New Roman', Times, serif;\r\n    text-transform: capitalize;\r\n}\r\n\r\n.breadcrumb-link:hover {\r\n    text-decoration: none;\r\n    color: var(--breadcrumb-link-color);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"breadcrumb-link": "Breadcrumb_breadcrumb-link__2QW53"
};
export default ___CSS_LOADER_EXPORT___;
