// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ActionsTable_actions-container__POZVc {\r\n    display: flex;\r\n    justify-content: flex-end;\r\n}\r\n\r\n.ActionsTable_actions-item-container__U5uCR+.ActionsTable_actions-item-container__U5uCR {\r\n    margin-left: 8px;\r\n}\r\n\r\n.ActionsTable_actions-item-container__U5uCR:hover {\r\n    cursor: pointer;\r\n}\r\n\r\n.ActionsTable_actions-item__x1vDo {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    padding: 8px 12px;\r\n    transition: all .3s;\r\n    border-radius: 5px;\r\n}\r\n\r\n.ActionsTable_actions-item__x1vDo:hover {\r\n    color: inherit;\r\n    text-decoration: none;\r\n}", "",{"version":3,"sources":["webpack://./src/components/ActionsTable/ActionsTable.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,qBAAqB;AACzB","sourcesContent":[".actions-container {\r\n    display: flex;\r\n    justify-content: flex-end;\r\n}\r\n\r\n.actions-item-container+.actions-item-container {\r\n    margin-left: 8px;\r\n}\r\n\r\n.actions-item-container:hover {\r\n    cursor: pointer;\r\n}\r\n\r\n.actions-item {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    padding: 8px 12px;\r\n    transition: all .3s;\r\n    border-radius: 5px;\r\n}\r\n\r\n.actions-item:hover {\r\n    color: inherit;\r\n    text-decoration: none;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"actions-container": "ActionsTable_actions-container__POZVc",
	"actions-item-container": "ActionsTable_actions-item-container__U5uCR",
	"actions-item": "ActionsTable_actions-item__x1vDo"
};
export default ___CSS_LOADER_EXPORT___;
