// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Button_button__wiIYs {\r\n\tbackground: var(--color-primary);\r\n\tborder-radius: 8px;\r\n\theight: 46px;\r\n\tjustify-content: center;\r\n\tmin-width: 138px;\r\n\tpadding: 12px 24px;\r\n\tborder: none !important;\r\n\toutline: none !important;\r\n\tfont-size: 16px;\r\n\tfont-weight: bold;\r\n\ttransition: all 0.2s ease-in-out;\r\n}\r\n\r\n.Button_button__wiIYs + .Button_button__wiIYs {\r\n\tmargin-left: 16px;\r\n}\r\n\r\n.Button_button__wiIYs.Button_disabled__PUkNe {\r\n\tpointer-events: none;\r\n\tuser-select: none;\r\n\t-webkit-user-select: none;\r\n\tcursor: default !important;\r\n\topacity: 0.5 !important;\r\n}\r\n\r\n.Button_button__wiIYs:hover {\r\n\tbackground: var(--primary-hover);\r\n\tcursor: pointer;\r\n\ttext-decoration: none;\r\n\tcolor: inherit;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/components/Button/Button.module.css"],"names":[],"mappings":"AAAA;CACC,gCAAgC;CAChC,kBAAkB;CAClB,YAAY;CACZ,uBAAuB;CACvB,gBAAgB;CAChB,kBAAkB;CAClB,uBAAuB;CACvB,wBAAwB;CACxB,eAAe;CACf,iBAAiB;CACjB,gCAAgC;AACjC;;AAEA;CACC,iBAAiB;AAClB;;AAEA;CACC,oBAAoB;CACpB,iBAAiB;CACjB,yBAAyB;CACzB,0BAA0B;CAC1B,uBAAuB;AACxB;;AAEA;CACC,gCAAgC;CAChC,eAAe;CACf,qBAAqB;CACrB,cAAc;AACf","sourcesContent":[".button {\r\n\tbackground: var(--color-primary);\r\n\tborder-radius: 8px;\r\n\theight: 46px;\r\n\tjustify-content: center;\r\n\tmin-width: 138px;\r\n\tpadding: 12px 24px;\r\n\tborder: none !important;\r\n\toutline: none !important;\r\n\tfont-size: 16px;\r\n\tfont-weight: bold;\r\n\ttransition: all 0.2s ease-in-out;\r\n}\r\n\r\n.button + .button {\r\n\tmargin-left: 16px;\r\n}\r\n\r\n.button.disabled {\r\n\tpointer-events: none;\r\n\tuser-select: none;\r\n\t-webkit-user-select: none;\r\n\tcursor: default !important;\r\n\topacity: 0.5 !important;\r\n}\r\n\r\n.button:hover {\r\n\tbackground: var(--primary-hover);\r\n\tcursor: pointer;\r\n\ttext-decoration: none;\r\n\tcolor: inherit;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "Button_button__wiIYs",
	"disabled": "Button_disabled__PUkNe"
};
export default ___CSS_LOADER_EXPORT___;
