// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Loading_loading-container__DlDDh {\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    bottom: 0;\r\n    right: 0;\r\n    display: flex;\r\n    background-color: rgba(255, 255, 255, 0.5);\r\n    z-index: 999999;\r\n}\r\n\r\n.Loading_loader__rS1ky {\r\n    margin: auto;\r\n    width: 55px;\r\n    height: 55px;\r\n    border: 8px solid rgba(0, 0, 0, 0.2);\r\n    border-bottom-color: var(--color-primary);\r\n    border-radius: 50%;\r\n    display: inline-block;\r\n    box-sizing: border-box;\r\n    animation: Loading_rotation__BhLSQ 1s linear infinite;\r\n}\r\n\r\n@keyframes Loading_rotation__BhLSQ {\r\n    0% {\r\n        transform: rotate(0deg);\r\n    }\r\n\r\n    100% {\r\n        transform: rotate(360deg);\r\n    }\r\n}", "",{"version":3,"sources":["webpack://./src/components/Loading/Loading.module.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,SAAS;IACT,QAAQ;IACR,aAAa;IACb,0CAA0C;IAC1C,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,YAAY;IACZ,oCAAoC;IACpC,yCAAyC;IACzC,kBAAkB;IAClB,qBAAqB;IACrB,sBAAsB;IACtB,qDAAsC;AAC1C;;AAEA;IACI;QACI,uBAAuB;IAC3B;;IAEA;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":[".loading-container {\r\n    position: fixed;\r\n    top: 0;\r\n    left: 0;\r\n    bottom: 0;\r\n    right: 0;\r\n    display: flex;\r\n    background-color: rgba(255, 255, 255, 0.5);\r\n    z-index: 999999;\r\n}\r\n\r\n.loader {\r\n    margin: auto;\r\n    width: 55px;\r\n    height: 55px;\r\n    border: 8px solid rgba(0, 0, 0, 0.2);\r\n    border-bottom-color: var(--color-primary);\r\n    border-radius: 50%;\r\n    display: inline-block;\r\n    box-sizing: border-box;\r\n    animation: rotation 1s linear infinite;\r\n}\r\n\r\n@keyframes rotation {\r\n    0% {\r\n        transform: rotate(0deg);\r\n    }\r\n\r\n    100% {\r\n        transform: rotate(360deg);\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading-container": "Loading_loading-container__DlDDh",
	"loader": "Loading_loader__rS1ky",
	"rotation": "Loading_rotation__BhLSQ"
};
export default ___CSS_LOADER_EXPORT___;
