// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Content_header_title__dWO57 {\r\n\tfont-weight: bold;\r\n\tfont-size: 20px;\r\n\tline-height: 1.6;\r\n\ttext-decoration: underline;\r\n\tfont-style: italic;\r\n\tpadding: 0 15px;\r\n}\r\n.Content_container_list_choosen__1qAG5 {\r\n\tdisplay: flex;\r\n\tflex-wrap: wrap;\r\n\talign-items: center;\r\n\tjustify-content: flex-start;\r\n\tpadding: 0 15px;\r\n}\r\n.Content_item_choosen__1WNoZ {\r\n\tdisplay: flex;\r\n\talign-items: center;\r\n\tjustify-content: center;\r\n\tmargin-right: 10px;\r\n\tmargin-bottom: 10px;\r\n}\r\n.Content_item_choosen__1WNoZ label {\r\n\tmargin: auto;\r\n\tfont-size: 16px;\r\n\tmargin-left: 5px;\r\n}\r\n.Content_container_contents__InjwD {\r\n\tmargin-top: 15px;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/Layouts/Contents/Content.module.css"],"names":[],"mappings":"AAAA;CACC,iBAAiB;CACjB,eAAe;CACf,gBAAgB;CAChB,0BAA0B;CAC1B,kBAAkB;CAClB,eAAe;AAChB;AACA;CACC,aAAa;CACb,eAAe;CACf,mBAAmB;CACnB,2BAA2B;CAC3B,eAAe;AAChB;AACA;CACC,aAAa;CACb,mBAAmB;CACnB,uBAAuB;CACvB,kBAAkB;CAClB,mBAAmB;AACpB;AACA;CACC,YAAY;CACZ,eAAe;CACf,gBAAgB;AACjB;AACA;CACC,gBAAgB;AACjB","sourcesContent":[".header_title {\r\n\tfont-weight: bold;\r\n\tfont-size: 20px;\r\n\tline-height: 1.6;\r\n\ttext-decoration: underline;\r\n\tfont-style: italic;\r\n\tpadding: 0 15px;\r\n}\r\n.container_list_choosen {\r\n\tdisplay: flex;\r\n\tflex-wrap: wrap;\r\n\talign-items: center;\r\n\tjustify-content: flex-start;\r\n\tpadding: 0 15px;\r\n}\r\n.item_choosen {\r\n\tdisplay: flex;\r\n\talign-items: center;\r\n\tjustify-content: center;\r\n\tmargin-right: 10px;\r\n\tmargin-bottom: 10px;\r\n}\r\n.item_choosen label {\r\n\tmargin: auto;\r\n\tfont-size: 16px;\r\n\tmargin-left: 5px;\r\n}\r\n.container_contents {\r\n\tmargin-top: 15px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header_title": "Content_header_title__dWO57",
	"container_list_choosen": "Content_container_list_choosen__1qAG5",
	"item_choosen": "Content_item_choosen__1WNoZ",
	"container_contents": "Content_container_contents__InjwD"
};
export default ___CSS_LOADER_EXPORT___;
